"use strict";

(function ($) {
    // Date picker
    $('.datepicker').each(function () {
        let txtDate = $(this);

        // Date picker
        let datepickerOptions = {};

        // Min date
        let attrMinDate = txtDate.data('min-date');

        if (attrMinDate) {
            datepickerOptions['startDate'] = attrMinDate;
        }

        // Max date
        let attrMaxDate = txtDate.data('max-date');

        if (attrMaxDate) {
            datepickerOptions['endDate'] = attrMaxDate;
        }

        // Register date picker
        txtDate.datepicker(datepickerOptions)
            /**
             * Listen for the change even on the input
             * @link https://stackoverflow.com/a/22507814/10174865
             */
            .on('changeDate', function (e) {
                // Hide picker
                $(this).datepicker('hide');

                let input = $(this);
                let field = input;
                let container = input.parent();
                let name = input.attr('name');
                // Split name to array to detect is display field or not
                let arrName = name.split('_');
                let isDisplayField = (arrName[arrName.length - 1] === 'display');

                if (isDisplayField) {
                    let fieldInfo = input.data('info');
                    field = container.find('[name="' + fieldInfo.name + '"]');
                }

                let date = $(this).datepicker('getDate');
                let strDate = moment(date).format('YYYY-MM-DD');

                if (isDisplayField && field.length > 0) {
                    field.val(strDate);
                }
            });
    });

    // Select2
    $('.select2').select2();
})(jQuery);